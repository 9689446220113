/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-newton-cradle,
.la-ball-newton-cradle > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.la-ball-newton-cradle {
    display: block;
    font-size: 0;
    color: #fff;
}

.la-ball-newton-cradle.la-dark {
    color: #333;
}

.la-ball-newton-cradle > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.la-ball-newton-cradle {
    width: 40px;
    height: 10px;
}

.la-ball-newton-cradle > div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.la-ball-newton-cradle > div:first-child {
    -webkit-transform: translateX(0%);
       -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
         -o-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-animation: ball-newton-cradle-left 1s 0s ease-out infinite;
       -moz-animation: ball-newton-cradle-left 1s 0s ease-out infinite;
         -o-animation: ball-newton-cradle-left 1s 0s ease-out infinite;
            animation: ball-newton-cradle-left 1s 0s ease-out infinite;
}

.la-ball-newton-cradle > div:last-child {
    -webkit-transform: translateX(0%);
       -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
         -o-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-animation: ball-newton-cradle-right 1s 0s ease-out infinite;
       -moz-animation: ball-newton-cradle-right 1s 0s ease-out infinite;
         -o-animation: ball-newton-cradle-right 1s 0s ease-out infinite;
            animation: ball-newton-cradle-right 1s 0s ease-out infinite;
}

.la-ball-newton-cradle.la-sm {
    width: 20px;
    height: 4px;
}

.la-ball-newton-cradle.la-sm > div {
    width: 4px;
    height: 4px;
}

.la-ball-newton-cradle.la-2x {
    width: 80px;
    height: 20px;
}

.la-ball-newton-cradle.la-2x > div {
    width: 20px;
    height: 20px;
}

.la-ball-newton-cradle.la-3x {
    width: 120px;
    height: 30px;
}

.la-ball-newton-cradle.la-3x > div {
    width: 30px;
    height: 30px;
}

/*
 * Animations
 */
@-webkit-keyframes ball-newton-cradle-left {
    25% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    50% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}
@-moz-keyframes ball-newton-cradle-left {
    25% {
        -moz-transform: translateX(-100%);
             transform: translateX(-100%);
        -moz-animation-timing-function: ease-in;
             animation-timing-function: ease-in;
    }
    50% {
        -moz-transform: translateX(0%);
             transform: translateX(0%);
    }
}
@-o-keyframes ball-newton-cradle-left {
    25% {
        -o-transform: translateX(-100%);
           transform: translateX(-100%);
        -o-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
    }
    50% {
        -o-transform: translateX(0%);
           transform: translateX(0%);
    }
}
@keyframes ball-newton-cradle-left {
    25% {
        -webkit-transform: translateX(-100%);
           -moz-transform: translateX(-100%);
             -o-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-timing-function: ease-in;
           -moz-animation-timing-function: ease-in;
             -o-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    50% {
        -webkit-transform: translateX(0%);
           -moz-transform: translateX(0%);
             -o-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@-webkit-keyframes ball-newton-cradle-right {
    50% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    75% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@-moz-keyframes ball-newton-cradle-right {
    50% {
        -moz-transform: translateX(0%);
             transform: translateX(0%);
    }
    75% {
        -moz-transform: translateX(100%);
             transform: translateX(100%);
        -moz-animation-timing-function: ease-in;
             animation-timing-function: ease-in;
    }
    100% {
        -moz-transform: translateX(0%);
             transform: translateX(0%);
    }
}

@-o-keyframes ball-newton-cradle-right {
    50% {
        -o-transform: translateX(0%);
           transform: translateX(0%);
    }
    75% {
        -o-transform: translateX(100%);
           transform: translateX(100%);
        -o-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
    }
    100% {
        -o-transform: translateX(0%);
           transform: translateX(0%);
    }
}

@keyframes ball-newton-cradle-right {
    50% {
        -webkit-transform: translateX(0%);
           -moz-transform: translateX(0%);
             -o-transform: translateX(0%);
                transform: translateX(0%);
    }
    75% {
        -webkit-transform: translateX(100%);
           -moz-transform: translateX(100%);
             -o-transform: translateX(100%);
                transform: translateX(100%);
        -webkit-animation-timing-function: ease-in;
           -moz-animation-timing-function: ease-in;
             -o-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: translateX(0%);
           -moz-transform: translateX(0%);
             -o-transform: translateX(0%);
                transform: translateX(0%);
    }
}
